import Flex from "@components/Container/Flex"
import { CardProgramSkeleton, SectionCardProgramList } from "../SkeletonHomePage"
import Skeleton from ".."

export function SectionCardProgram() {
  return <div className="grid grid-cols-1 md:grid-cols-5 gap-4 lg:gap-6 w-full">
    <CardProgramSkeleton />
    <CardProgramSkeleton />
    <CardProgramSkeleton />
    <CardProgramSkeleton />
    <CardProgramSkeleton />
  </div>
}

function SkeletonProgramsAll() {
  return <Flex layout="column" gap="medium">
    <Flex justify="between" items="center" layout="row" customClass="container mx-auto mt-10">
      <Flex layout="column" gap="small">
        <Skeleton classSize="w-80 lg:w-96 h-4" bgColor="gray" rounded="lg" />
        <Skeleton classSize="w-80 lg:w-96 h-4" bgColor="gray" rounded="lg" />
        <div className="lg:hidden block w-full">
          <Skeleton classSize="w-32 lg:w-80 h-4" bgColor="gray" rounded="lg" />
        </div>
      </Flex>
      <Flex layout="column" customClass="hidden lg:flex">
        <Skeleton classSize="w-20 lg:w-72 h-4" bgColor="gray" rounded="lg" />
      </Flex>
    </Flex>
    <div className="lg:block hidden w-full mt-6">
      <SectionCardProgram />
      <SectionCardProgram />
      <SectionCardProgram />
    </div>
    <div className="lg:hidden flex gap-4 flex-col w-full">
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
      <SectionCardProgramList />
    </div>
  </Flex>
}

export default SkeletonProgramsAll
