import Flex from "@components/Container/Flex"
import Skeleton from ".."
import { SearchFieldSkeleton } from "../SkeletonHomeSearch"

export function SectionCardProgramList() {
  return <Flex layout="row" gap="normal" customClass="mt-2">
    <Skeleton classSize="h-16 w-24 lg:w-32" rounded="md" />

    <Flex layout="column" gap="small">
      <Flex layout="row" gap="small">
        <Skeleton classSize="h-4 w-16 lg:w-20" rounded="md" />
        <Skeleton classSize="h-4 w-20" rounded="md" />
      </Flex>
      <Flex layout="column" gap="normal">
        <Skeleton classSize="h-4 w-28 lg:w-32" rounded="md" />
        <Skeleton classSize="h-2 w-24 lg:w-32" rounded="md" />
      </Flex>
      <Flex layout="column" gap="normal" customClass="mt-2">
        <Skeleton classSize="h-4 w-20 lg:w-32" rounded="md" />
      </Flex>
    </Flex>
  </Flex>
}
export function CardProgramSkeleton() {
  return <Flex
    justify="start"
    items="start"
    layout="column"
    gap="normal"
    customClass="bg-white w-full h-72">
    <Skeleton classSize="w-full h-32" bgColor="gray" rounded="lg" />
    <Skeleton classSize="w-44 lg:w-44 h-4" bgColor="gray" rounded="lg" />
    <Skeleton classSize="w-32 lg:w-32 h-4" bgColor="gray" rounded="lg" />
    <Skeleton classSize="w-24 lg:w-24 h-4" bgColor="gray" rounded="lg" />
    <Skeleton classSize="w-32 lg:w-32 h-4" bgColor="gray" rounded="lg" />
  </Flex>
}

function SectionCardProgramSkeleton() {
  return <>
    <Flex justify="between" items="center" layout="row" customClass="container mx-auto px-2 mt-10">
      <Skeleton classSize="w-20 lg:w-56 h-6" bgColor="gray" rounded="lg" />
      <Skeleton classSize="w-20 lg:w-32 h-4" bgColor="gray" rounded="lg" />
    </Flex>
    <Flex
      justify="between"
      gap="medium"
      items="center"
      layout="row"
      customClass="container mx-auto px-2 mt-8 overflow-scroll">
      <CardProgramSkeleton />
      <CardProgramSkeleton />
      <CardProgramSkeleton />
      <CardProgramSkeleton />
      <CardProgramSkeleton />
    </Flex>
  </>
}
function SkeletonHomePage() {
  return <section className="mt-16 lg:mt-8 px-4">
    <SearchFieldSkeleton />
    <div className="lg:block hidden w-full">
      <SectionCardProgramSkeleton />
      <SectionCardProgramSkeleton />
      <SectionCardProgramSkeleton />
    </div>
    <div>
      <div className="lg:hidden flex gap-4 flex-col w-full">
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
        <SectionCardProgramList />
      </div>
    </div>
  </section>
}

export default SkeletonHomePage
