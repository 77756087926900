import Flex from "@components/Container/Flex"
import Skeleton from ".."

export function IconCategoryList() {
  return <Flex items="center" layout="column" gap="normal">
    <Skeleton classSize="h-8 w-8" rounded="lg" bgColor="gray" />
    <Skeleton classSize="h-4 w-8" rounded="lg" bgColor="gray" />
    <Skeleton classSize="h-4 w-20" rounded="lg" bgColor="gray" />
  </Flex>
}

export function SearchFieldSkeleton() {
  return <>
    <div className="container mx-auto rounded-lg p-2 bg-white shadow hidden lg:grid grid-cols-8 gap-4 place-items-center">
      <div className="col-span-2 w-full">
        <Skeleton classSize="h-14 w-full" rounded="lg" bgColor="gray" />
      </div>
      <div className="col-span-5 w-full">
        <Skeleton classSize="h-14 w-full" rounded="lg" bgColor="gray" />
      </div>
      <div className="col-span-1 flex w-full">
        <Skeleton classSize="h-14 w-full" rounded="lg" bgColor="gray" />
      </div>
    </div>
    <Flex
      justify="between"
      items="start"
      layout="column"
      gap="small"
      customClass="px-6 py-4 bg-white rounded-lg shadow-lg relative -top-8 lg:hidden">
      <Skeleton classSize="h-12 w-full" rounded="lg" bgColor="gray" />
      <Skeleton classSize="h-12 w-full" rounded="lg" bgColor="gray" />
      <Skeleton classSize="h-12 w-full" rounded="lg" bgColor="gray" />
    </Flex>
  </>
}

function SkeletonHomeSearch() {
  return <section>
    <div className="relative bg-primary pt-8 pb-8 sm:pt-20 sm:pb-0">
        <div className="container mx-auto">
          <div className="sm:my-auto mx-auto w-full sm:w-2/3">
            <div className="relative z-10">
              <div className="relative">
                <Skeleton classSize="h-10 w-full mb-4" rounded="lg" bgColor="primary-light" />
                <Skeleton classSize="h-10 w-full mb-8" rounded="lg" bgColor="primary-light" />
                <Skeleton classSize="h-12 w-full" rounded="lg" bgColor="primary-light" />
              </div>
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div className="container mx-auto pb-4 sm:pb-8 px-0 sm:px-20 mt-6 pt-6 sm:pt-0">
          <SearchFieldSkeleton />
        </div>
        <div className="container mx-auto">
          <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-6 py-4 pt-8 sm:pt-8 sm:pb-16 px-4 sm:px-0">
            {[1,2,3,4,5,6].map((item) =>
              <div className="p-[0.8rem] rounded sm:rounded-lg bg-[#FFFFFF14] mx-0 border border-white" key={item}>
                <IconCategoryList />
              </div>,
            )}
          </div>
        </div>
      </div>
  </section>
}

export default SkeletonHomeSearch
