import { colorType } from "lib/interfaces"

type Rounded = "sm" | "md" | "lg" | "xl" | "full"

interface SkeletonProps {
  classSize: string
  rounded: Rounded
  bgColor?: colorType
}

function Skeleton({ classSize = "w-20 h-20", rounded = "full", bgColor = "gray" }: SkeletonProps) {
  let customBgColor = ""
  let roundedSkeleton = ""

  switch (bgColor) {
    case "light":
      customBgColor = "bg-white"
      break
    case "dark":
      customBgColor = "bg-black"
      break
    case "secondary":
      customBgColor = "bg-secondary"
      break
    case "primary":
      customBgColor = "bg-primary"
      break
    case "primary-light":
      customBgColor = "bg-blue-400"
      break
    default:
      customBgColor = "bg-gray-200"
  }

  switch (rounded) {
    case "sm":
      roundedSkeleton = "rounded-sm"
      break
    case "md":
      roundedSkeleton = "rounded-md"
      break
    case "lg":
      roundedSkeleton = "rounded-lg"
      break
    case "xl":
      roundedSkeleton = "rounded-xl"
      break
    case "full":
      roundedSkeleton = "rounded-full"
      break
    default:
      roundedSkeleton = "rounded-sm"
  }
  return <div className={`${classSize} animate-pulse ${customBgColor} ${roundedSkeleton}`} />
}
export default Skeleton
