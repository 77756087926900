import { ReactNode } from "react"

type Justify = "center" | "between" | "start" | "end" | "around"
type Items = "center" | "start" | "end" | "baseline" | "stretch"

interface FlexProps {
  justify?: Justify
  items?: Items
  children: ReactNode
  customClass?: string
  layout: "row" | "column"
  gap?: "small" | "normal" | "medium" | "large"
}

function Flex({ justify, items, children, customClass, layout, gap }: FlexProps) {
  let flexJustify = ""
  let flexItems = ""
  let flexLayout = ""
  let flexGap = ""

  switch (justify) {
    case "around":
      flexJustify = "justify-around"
      break
    case "between":
      flexJustify = "justify-between"
      break
    case "center":
      flexJustify = "justify-center"
      break
    case "end":
      flexJustify = "justify-end"
      break
    default:
      flexJustify = "justify-start"
  }

  switch (items) {
    case "baseline":
      flexItems = "items-baseline"
      break
    case "stretch":
      flexItems = "items-stretch"
      break
    case "center":
      flexItems = "items-center"
      break
    case "end":
      flexItems = "items-end"
      break
    default:
      flexItems = "items-start"
  }

  switch (layout) {
    case "row":
      flexLayout = "flex-row"
      break
    default:
      flexLayout = "flex-col"
  }

  switch (gap) {
    case "small":
      flexGap = "gap-2"
      break
    case "normal":
      flexGap = "gap-4"
      break
    case "medium":
      flexGap = "gap-6"
      break
    case "large":
      flexGap = "gap-8"
      break
    default:
  }

  return (
    <div className={`flex ${flexJustify} ${customClass} ${flexItems} ${flexLayout} ${flexGap}`}>
      {children}
    </div>
  )
}

export default Flex
