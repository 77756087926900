import Select, { components } from "react-select"
import Text from "lib/components/Text"
import { CSSProperties, forwardRef, useEffect, useState } from "react"
import Icon from "../Icons"

type OptionsSelect = {
  value: string
  label: string
  disabled?: boolean
  showIcon?: boolean
}

export interface SelectWithSearchProps {
  name?: string
  inline?: boolean
  label: string
  success?: boolean
  error?: boolean
  message?: string
  disabled?: boolean
  placeholder: React.ReactNode
  onChange: (event) => void
  handleInputChange?: (event) => void
  value?: OptionsSelect[] | OptionsSelect
  options: OptionsSelect[]
  hasSelectAll?: boolean
  isLoading?: boolean
  noBorder?: boolean
  customCss?: CSSProperties
  noOptionsMessage?: string
  isSearchable?: boolean
  onlyBorderBottom?: boolean
  labelColor?: "gray" | "dark"
  onFocus?: (object) => void
  onBlur?: (object) => void
  autoFocus?: boolean
}

function checkFocused(value) {
  if (value) {
    return "#286DCC"
  }
  
  return "#D1D5DB"
}

const customStyles = (customCss, noBorder = false, error = false, onlyBorderBottom = false) => ({
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: "#343A40",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999, color: "#343A40" }),
  control: (styles, { isDisabled, isFocused }) => ({
    ...customCss,
    ...styles,
    cursor: isDisabled ? "not-allowed" : "default",
    backgroundColor: isDisabled ? "#E5E7EB" : "white",
    borderColor: error ? "red" : checkFocused(isFocused),
    borderWidth: noBorder ? 0 : 1,
    borderBottomWidth: (onlyBorderBottom || !noBorder) ? 1 : 0,
    boxShadow: noBorder ? "none" : "initial",
    "&:hover": {
      borderColor: (noBorder && !onlyBorderBottom) ? "transparent" : checkFocused(isFocused),
    },
  }),
  valueContainer: (styles) => ({ ...styles, marginLeft: "0.25rem" }),
  placeholder: (styles) => ({ ...styles, fontSize: "0.9rem", color: "gray" }),
  dropdownIndicator: (styles) => ({
    ...styles,
    display: (noBorder && !onlyBorderBottom) ? "none" : "flex",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: onlyBorderBottom ? "none" : "flex",
  }),
  groupHeading: (styles) => ({
    ...styles,
    color: "#343A40",
    fontSize: "0.975rem",
    // paddingTop: "0.5rem",
    // borderTop: "1px solid #D1D5DB",
    textTransform: "capitalize",
  }),
  menu: provided => ({ ...provided, zIndex: 9999, color: "#343A40" }),
})

const { Option } = components
  const IconOption = props => (
    <Option {...props}>
      <div  className="flex gap-x-2 items-center">
        {props.data.label}
        {props.data.showIcon &&
          <Icon 
            iconName="setting"
            width={16}
            height={16}
            color="#959EAD"
            />
        }
      </div>
    </Option>
  )


const SelectWithSearch = forwardRef<HTMLInputElement, SelectWithSearchProps>(
  (
    {
      name,
      inline,
      label,
      error,
      message,
      disabled,
      placeholder,
      onChange,
      options,
      value,
      handleInputChange,
      isLoading = false,
      noBorder = false,
      customCss,
      noOptionsMessage = "No Options",
      isSearchable = true,
      onlyBorderBottom = false,
      labelColor = "gray",
      onFocus,
      onBlur,
      autoFocus = false,
    }: SelectWithSearchProps,
    selectSearchRef,
  ) => {
    const [bodyElement, setBodyElement] = useState(null)
    SelectWithSearch.displayName = "SelectWithSearch"
    let inlineStyle = "flex-col"
    let labelStyle = "mb-2"
    let labelValue = <div />

    if (inline) {
      inlineStyle = "flex-row items-center"
      labelStyle = "mr-2"
    }

    if (label) {
      if (label.includes("*")) {
        labelValue = (
          <div className="flex gap-x-1">
            <Text type="small-body" color={labelColor}>
              {label.replace(" *", "")}
            </Text>
            <Text weight="bold" type="small-body" color="danger">
              *
            </Text>
          </div>
        )
      } else {
        labelValue = (
          <Text type="small-body" color={labelColor}>
            {label}
          </Text>
        )
      }
    }

    useEffect(() => {
      setBodyElement(document.body)
    }, [])

    return (
      <div className={`flex ${inlineStyle} `}>
        {label && (
          <div className={labelStyle}>
            {labelValue}
          </div>
        )}
        <div className="relative flex-1">
          <Select
            menuPortalTarget={bodyElement}
            instanceId={name}
            options={options && options.length > 0 ? options : []}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onInputChange={handleInputChange}
            isDisabled={disabled}
            isSearchable={isSearchable}
            name={name}
            placeholder={placeholder}
            styles={customStyles(customCss, noBorder, error, onlyBorderBottom)}
            value={value}
            isLoading={isLoading}
            aria-label={name}
            noOptionsMessage={() => noOptionsMessage}
            autoFocus={autoFocus}
            components={{ 
              Option: IconOption,
            }}
          />
        </div>
        {error && (
          <div className="mt-1">
            <Text type="small-body" color="danger">
              {message}
            </Text>
          </div>
        )}
      </div>
    )
  },
)

export default SelectWithSearch
