
import dynamic from "next/dynamic"
import { isMobile } from "react-device-detect"

const ReactPaginate: any = dynamic(() => import("react-paginate"), { ssr: false })

export interface PaginationProps {
  totalData?: number
  activePage?: number
  onChange?: (event) => void
  limit?: number
  fullWidth?: boolean
  marginVerticalSize?: "small" | "medium" | "large"
  noPageRange?: boolean
}
 
function Pagination({ totalData = 0, onChange, activePage, limit = 12, fullWidth = true, marginVerticalSize = "medium", noPageRange = false }: PaginationProps) {
  let pageDisplayed = 5
  let pageRange = 3
  let breakLabelValue = "..."

  if (isMobile) {
    pageDisplayed = 2
    pageRange = 1
  }

  if (noPageRange) {
    pageDisplayed = 0
    pageRange = 5
    breakLabelValue = null
  }

  let sizeWidth = ""
  let marginValue = "my-8"

  if (fullWidth) {
    sizeWidth = "w-full"
  }

  switch (marginVerticalSize) {
    case "small":
      marginValue = "my-4"
      break
    case "large":
      marginValue = "my-16"
      break
    default:
      break
  }

  return (
    <div className={`${sizeWidth} ${marginValue}`}>
      <ReactPaginate
        previousLabel="Prev"
        nextLabel="Next"
        breakLabel={breakLabelValue}
        breakClassName="rounded border m-1 px-3 py-1.5 sm:px-4 sm:py-2"
        pageCount={Math.ceil(totalData / limit)}
        marginPagesDisplayed={pageDisplayed}
        pageRangeDisplayed={pageRange}
        onPageChange={onChange}
        containerClassName="flex flex-row items-center justify-end mt-4 mx-4 list-none m-0 p-0"
        activeLinkClassName="rounded px-3 py-1.5 sm:px-4 sm:py-2 bg-primary text-white m-1"
        pageLinkClassName="rounded m-1 px-3 py-1.5 sm:px-4 sm:py-2 border hover:bg-primary text-gray-300"
        previousLinkClassName="rounded px-3 py-1.5 sm:px-4 sm:py-2 text-gray-300 border"
        nextLinkClassName="rounded px-3 py-1.5 sm:px-4 sm:py-2 text-gray-300 border"
        disabledClassName="rounded py-1.5 border-0 bg-gray-200"
        forcePage={activePage - 1}
      />
    </div>
   )
 }

export default Pagination
